import { useRouter } from 'next/router'
import { FC } from 'react'

import AuthPopupWrapper from '../auth-popup-wrapper/auth-popup-wrapper'
import RegistrationForm from './double-registration/parts/registration-step1/registration-step1'

import A from 'utils/metrics/analytic'
import { A_REGISTRATION_SUCCESS } from 'constants/analytic-data'
import { SessionPopupsKeys } from 'constants/storage'
import { cutHashFromURL } from 'utils/getURLData'
import { popupName } from 'constants/auth/auth-data'

const RegGeriatricsSchoolPopup: FC = () => {
  const router = useRouter()
  const path = cutHashFromURL(router.asPath)

  const subToSchool = async () => {
    sessionStorage.setItem(SessionPopupsKeys.GERIATRICS_SCHOOL_GER_POPUP, 'true')
    A.setEvent(A_REGISTRATION_SUCCESS, { subSource: 'online_school_geriatrics_24' })
  }

  const showLoginForm = () => {
    router.push(`${path}#${popupName.loginGeriatricsSchool}`, undefined, { shallow: true })
  }

  return (
    <AuthPopupWrapper>
      <RegistrationForm onSubmitEnd={subToSchool} handleLoginButton={showLoginForm} />
    </AuthPopupWrapper>
  )
}

export default RegGeriatricsSchoolPopup
