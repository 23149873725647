import { FC, useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'

import Button from 'components/parts/button/button'
import ControllerBaseInput from 'components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import ControllerCalendarSelect from 'components/form-parts/controller-hook-forms/controller-calendar-select/controller-calendar-select'

import A from 'utils/metrics/analytic'
import { A_REGISTRATION_2ND_STEP } from 'constants/analytic-data'
import { putAxiosSingle } from 'pages/api/axios'
import { profileURL } from 'constants/url'
import { IObj } from 'constants/types/common.types'
import { FieldsNameVoc, fullNameValidation, workplceValidation } from 'constants/auth/profile-data'

import styles from './registration-step2.module.scss'

interface IRegistrationStep2 {
  handleClose?: () => void
}

const InputPCStyles = {
  inputNameModificator: styles.nameRowStyle,
  innerContainerModificator: styles.inputWrap,
}

const RegistrationStep2: FC<IRegistrationStep2> = ({ handleClose }) => {
  const { handleSubmit, control, setValue, clearErrors, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [FieldsNameVoc.fullName]: '',
      [FieldsNameVoc.workplace]: '',
      [FieldsNameVoc.calendarSelect]: '',
    },
  })

  const { errors } = useFormState({ control })

  const sendAnalytics = () => {
    const { fullName, workplace, calendarSelect } = getValues()
    if (fullName) A.setEvent(A_REGISTRATION_2ND_STEP, { progress: '1' })
    if (workplace) A.setEvent(A_REGISTRATION_2ND_STEP, { progress: '2' })
    if (calendarSelect) A.setEvent(A_REGISTRATION_2ND_STEP, { progress: '3' })
  }

  const onSubmit = async (values: IObj<string>) => {
    const [lastName, firstName, secondName] = values.fullName.trim().split(' ')

    const formatedValues = {
      firstName: firstName,
      lastName: lastName,
      secondName: secondName,
      certificateExpirationDate: new Date(values.calendarSelect),
      workplace: values.workplace,
    }
    await putAxiosSingle(profileURL, {}, formatedValues)
    handleClose?.()
    sendAnalytics()
  }

  useEffect(() => {
    return () => {
      onSubmit(getValues())
      sendAnalytics()
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.txtTop}>Заполните поля для полного доступа к порталу.</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControllerBaseInput
          control={control}
          name={FieldsNameVoc.fullName}
          errors={errors}
          noErrorOnTouch={clearErrors}
          rules={fullNameValidation}
          nameStar
          {...InputPCStyles}
        />

        <ControllerBaseInput
          control={control}
          name={FieldsNameVoc.workplace}
          rules={workplceValidation}
          noErrorOnTouch={clearErrors}
          errors={errors}
          nameStar
          {...InputPCStyles}
        />
        <ControllerCalendarSelect
          control={control}
          name={FieldsNameVoc.calendarSelect}
          nameInput="Дата следующей аккредитации"
          placeholder="Выберите дату"
          setValue={setValue}
          clearErrors={clearErrors}
          errors={errors}
        />
        <Button>Сохранить</Button>
      </form>
    </div>
  )
}

export default RegistrationStep2
